* {
    z-index: 50;
}

.background {
    z-index: 0;
    background: #EFF2F4;
}

.backgroundLogin {
    background: #EFF2F4;
}

.is-active{
    background-color: red;
    color: #fff;
}