@font-face {
    font-family: 'Comfortaa';
    src: url('../fonts/Comfortaa-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Gibson';
    src: url('../fonts/Gibson-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Varela';
    src: url('../fonts/VarelaRound-Regular.ttf') format('truetype');
}

.font {
    font-family: 'Comfortaa', sans-serif;
}
